import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay';

import { trackPageview, trackUserEventPageView } from 'dibs-tracking';
import { trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';
import { pageTypeConstants as PAGE_TYPES, pageTypeConstants } from '../constants/pageTypeConstants';
import { getPageviewData } from '../utils/tracking/searchBrowse/getPageviewData';

import { SharedUserAndRelayVarLoader } from '../shared/SharedUserAndRelayVarLoader';
import { handlePageRefetchVariableOnUserChange } from '../shared/handlePageRefetchVariableOnUserChange';

import { updateRelayVariables } from '../actions/sharedRelayVariableActions';
import { toggleRequestInFlight, updateUriRef } from '../actions/filterActions';

import { getNewPaginationUriRef, addCdnQueryParams } from '../utils/uriUtils';

import { FindingRouter } from '../router/FindingRouter';
import {
    personalizedRerankTracking,
    trackUrgencySignals,
} from '../finding/SbSharedTrackingFunctions/SbSharedTrackingFunctions';
import { trackEligibleForRerankEvent } from '../utils/tracking/searchBrowse/addPersonalizationTrackingData';

import { handleSbUserCountryCode } from '../finding/helpers/handleSbUserCountryCode';

class BuySharedRefetchContainerComponent extends Component {
    constructor() {
        super();

        this.handleUserRefetch = this.handleUserRefetch.bind(this);
        this.handleBuyPageRefetch = this.handleBuyPageRefetch.bind(this);
        this.refetchCallback = this.refetchCallback.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

        this.hasFiredEligibleForRerankEvent = createRef();
    }

    componentDidMount() {
        const { buyPage, relay } = this.props;
        const {
            pageType,
            totalResults,
            appliedFilters,
            displayUriRef,
            appliedPersonalizationList,
        } = buyPage;
        this.buyPageName = appliedFilters.find(
            f => f.name === PAGE_TYPES.BUY
        )?.values?.[0]?.urlLabel;
        this.router = new FindingRouter({ routes: [{ path: '/buy/:pageName?' }] });
        this.props.updateUriRef({ uriRef: displayUriRef });
        trackPageview({
            additional: {
                ...getPageviewData({
                    pageType,
                    totalResults,
                    appliedFilters,
                }),
                dimension143: appliedPersonalizationList?.length
                    ? appliedPersonalizationList
                    : null,
            },
        });

        trackUserEventPageView(relay.environment);

        personalizedRerankTracking({
            pageType: pageTypeConstants.BUY,
            sortValue: buyPage?.sort?.currentOption?.urlLabel,
        });
        trackUrgencySignals();

        buyPage.trackedAbTests?.forEach(abTest => {
            trackAbTestV2Variant(abTest);
        });
    }

    componentDidUpdate(prevProps) {
        const { generatedUriRef, page: newPage, viewer, buyPage } = this.props;
        const oldUriRef = addCdnQueryParams(prevProps.generatedUriRef);
        const newUriRef = addCdnQueryParams(generatedUriRef);
        const uriRefChanged = oldUriRef !== '' && newUriRef !== oldUriRef;
        const pageChanged = newPage !== prevProps.page;
        let uriRef = generatedUriRef;

        if (pageChanged) {
            uriRef = getNewPaginationUriRef(generatedUriRef, newPage);
            this.handlePageChange(newPage);
        }

        if (uriRefChanged) {
            const variables = { uriRef, page: newPage };
            this.props.toggleRequestInFlight(true);
            this.handleBuyPageRefetch(
                { ...variables, fetchBuy: true, skipUser: true },
                null,
                this.refetchCallback
            );
            this.props.updateRelayVariables(variables);
        }

        if (viewer?.isEligibleForRerank && !this.hasFiredEligibleForRerankEvent.current) {
            trackEligibleForRerankEvent();
            this.hasFiredEligibleForRerankEvent.current = true;
        }

        buyPage.trackedAbTests?.forEach(abTest => {
            if (abTest && !prevProps.buyPage.trackedAbTests?.includes(abTest)) {
                trackAbTestV2Variant(abTest);
            }
        });
    }

    refetchCallback() {
        this.props.toggleRequestInFlight(false);
        this.props.updateUriRef({ uriRef: this.props?.buyPage?.displayUriRef });
    }

    handlePageChange(page) {
        if (this.buyPageName) {
            this.router.navigate(
                getNewPaginationUriRef(`/${PAGE_TYPES.BUY}/${this.buyPageName}/`, page),
                { replace: true }
            );
        }
    }

    handleUserRefetch(variables, renderVariables, callback) {
        const { buyPage, relayVariables } = this.props;
        const { displayUriRef } = buyPage || {};
        const { followSearchPages } = relayVariables || {};
        //Personalized-rerank needs to refetch s&b after login
        const fetchBuy =
            handlePageRefetchVariableOnUserChange(variables) || variables.fetchSbOnUserChange;
        this.handleBuyPageRefetch(
            {
                ...variables,
                fetchBuy,
                skipUser: false,
                followSearchPages: displayUriRef ? [displayUriRef] : followSearchPages,

                userCountryCode: handleSbUserCountryCode({
                    userCountryCode: variables?.userCountryCode,
                    fetchSb: variables?.fetchSb,
                }),
            },
            renderVariables,
            callback
        );
    }

    handleBuyPageRefetch(variables, renderVariables, callback) {
        this.props.refetch(variables, renderVariables, callback);
    }

    render() {
        return <SharedUserAndRelayVarLoader onUserRefetch={this.handleUserRefetch} />;
    }
}

function mapStateToProps(state) {
    const { filters, relayVariables } = state;
    const { generatedUriRef, page } = filters;

    return {
        page,
        generatedUriRef,
        relayVariables: relayVariables.variables,
    };
}

const mapDispatchToProps = {
    toggleRequestInFlight,
    updateUriRef,
    updateRelayVariables,
};

BuySharedRefetchContainerComponent.propTypes = {
    relay: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired,
    generatedUriRef: PropTypes.string,
    toggleRequestInFlight: PropTypes.func.isRequired,
    updateUriRef: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    updateRelayVariables: PropTypes.func.isRequired,
    buyPage: PropTypes.object,
    viewer: PropTypes.object,
    relayVariables: PropTypes.shape({
        followSearchPages: PropTypes.arrayOf(PropTypes.string),
        userCountryCode: PropTypes.string,
        priceBookName: PropTypes.string,
    }).isRequired,
};

export const BuySharedRefetchContainer = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(BuySharedRefetchContainerComponent),
    {
        viewer: graphql`
            fragment BuySharedRefetchContainer_viewer on Viewer {
                isEligibleForRerank(userId: $rerankUserId, guestId: $rerankGuestId)
                    @include(if: $isClient)
            }
        `,
        buyPage: graphql`
            fragment BuySharedRefetchContainer_buyPage on ItemSearchQueryConnection {
                trackedAbTests
                pageType
                totalResults
                appliedFilters {
                    name
                    values {
                        displayName
                        urlLabel
                        code
                    }
                }
                sort {
                    currentOption {
                        urlLabel
                    }
                }
                displayUriRef
                appliedPersonalizationList
            }
        `,
    }
);
